<template>
  <select
    class="LocaleSwitcher"
    v-model="$i18n.locale">
    <option
      v-for="locale in $i18n.availableLocales"
      :value="locale"
      :key="`locale-${locale}`">
      {{ locale }}
    </option>
  </select>
</template>

<style lang="scss" scoped>
@import '@/styles/_variables';

.LocaleSwitcher {
  position: absolute;
  top: 16px;
  right: 16px;
}
</style>
